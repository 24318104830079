<template>
    <searchBody>
        <search :show-create="true" create-text="添加原因" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table :key="tableKey" stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="applyModel">
                    {{ { '1': '退货退款', '2': '仅退款' }[row.applyModel] }}
                </template>
                <template slot-scope="{ row }" slot="ctime">
                    {{ new Date(row.ctime * 1000).Format('yyyy-MM-dd HH:mm:ss') }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ row.status == 1 ? '启用' : '禁用' }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>

        <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">类型</div>
                <Select v-model="formData.applyModel">
                    <Option value="1">退货退款</Option>
                    <Option value="2">仅退款</Option>
                </Select>
            </div>
            <div class="form_item">
                <div class="form_label align_self_start must_input">原因</div>
                <Input type="text" v-model.trim="formData.applyNote" :maxlength="20" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">是否启用</div>
                <RadioGroup v-model="formData.status" type="button" button-style="solid">
                    <Radio label="1">是</Radio>
                    <Radio label="0">否</Radio>
                </RadioGroup>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
            </div>
        </Modal>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqApplyInfoList, reqDelApplyInfo, reqSaveApplyInfo } from '@/lib/request/auth2';
import { isFunction } from '@/lib/util';

export default {
    name: 'goodOrderList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [
                {
                    id: hm.createId(),
                    label: '退款状态',
                    placeholder: '选择退款状态',
                    type: 'select',
                    bind: 'applyModel',
                    list: [
                        { id: '1', title: '仅退款' },
                        { id: '2', title: '退货退款' },
                    ],
                },
            ],
            tableKey: 'tableKey',
            dataColumn: [
                { slot: 'applyModel', title: '售后类型', minWidth: 80 },
                { key: 'applyNote', title: '内容', minWidth: 150, tooltip: true },
                { slot: 'status', title: '状态', minWidth: 100 },
                { slot: 'ctime', title: '创建时间', minWidth: 120 },
                { slot: 'manager', title: '操作', minWidth: 140 },
            ],
            dataList: [],
            showCreate: false,
            title: '添加售后原因',
            formData: {
                id: null,
                applyModel: '1',
                applyNote: null,
                status: '1',
            },
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            reqApplyInfoList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_row) {
            this.title = '添加售后原因';
            this.formData.applyModel = '1';
            this.formData.applyNote = null;
            this.formData.status = '1';
            this.formData.id = null;
            if (hm.isNotNullObject(_row)) {
                this.title = '编辑售后原因';
                let { id, applyModel, applyNote, status } = _row;
                this.formData.id = id;
                this.formData.applyModel = applyModel;
                this.formData.applyNote = applyNote;
                this.formData.status = status;
            }
            this.showCreate = true;
        },
        //删除
        onDelete(info = {}) {
            let { id } = info || {};
            this.confirmDialog(`确认删除这条数据吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDelApplyInfo({ id })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .finally(() => {
                            this.hideAjaxLoading();
                        });
                }
            });
        },
        //修改状态-确认
        onBeforeChangeStatus() {
            return new Promise((resolve, reject) => {
                this.confirmDialog(`确认修改状态吗？`).then(isOK => {
                    if (isOK == 1) {
                        resolve();
                    } else {
                        reject();
                    }
                });
            });
        },
        //修改状态
        onChangeStatus(row) {
            let { status } = row;
            let targetStatus = status == 0 ? 1 : 0;
            this.fadeAlert(`${targetStatus == 1 ? '启用' : '禁用'}成功`);
            this.getList();
        },
        onCancelCreate(cb = null) {
            this.showCreate = false;
            setTimeout(() => {
                this.hideAjaxLoading();
                isFunction(cb) && cb();
            }, 500);
        },
        onConfirmCreate() {
            let applyNote = this.formData.applyNote;
            if (!hm.isNotEmptyString(applyNote)) {
                this.fadeWarning('请输入原因');
                return;
            }
            this.showAjaxLoading();
            reqSaveApplyInfo(this.formData)
                .then(res => {
                    this.fadeAlert(res.errmsg);
                    this.onCancelCreate(() => {
                        this.getList();
                    });
                })
                .catch(res => {
                    this.fadeWarning(res.errmsg);
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
<style lang="less" scoped>
.table_container {
    & .ivu-switch span {
        font-size: revert;
    }
}
.form_label {
    width: 120px;
}
</style>
